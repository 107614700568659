type GenericErrorMessageProps = {
  readonly reset: () => void;
};
export function GenericErrorMessage({
  reset
}: GenericErrorMessageProps) {
  return <div className="max-w-full flex-none p-4 text-center" data-sentry-component="GenericErrorMessage" data-sentry-source-file="GenericErrorMessage.component.tsx">
      <h2 className="pb-2 text-2xl font-bold lg:text-3xl">
        Something went wrong
      </h2>
      <button className="underline" onClick={() => reset()} type="button">
        Try again
      </button>
    </div>;
}